// Switcher
const config = {
  "darkmode": "darkmode", // Mode: Class
  "rounded": "rounded",
  "sticky": "sticky",
  "typo": "typo",
  "shadow": "shadow",
}

const addBodyClasses = (classes) => {
  classes.split(" ").map(cssClass => {
    document.body.classList.add(cssClass.trim());
  });
}
const removeBodyClasses = (classes) => {
  classes.split(" ").map(cssClass => {
    document.body.classList.remove(cssClass.trim());
  });
}

const onClick = (setting) => (event) => {
  const storageKey = `mode-${setting}`
  const currentActiveState = localStorage.getItem(storageKey) != null;

  event.target.dispatchEvent(new CustomEvent("toggle-setting", {
    bubbles: true,
    cancelable: true,
    detail: {
      setting,
      newActiveState: !currentActiveState,
    }
  }));
}

/**
 * Event listener which changes the body classes
 */
window.addEventListener("toggle-setting", (event) => {
  const { setting, newActiveState } = event.detail;
  const storageKey = `mode-${setting}`;

  if (newActiveState) {
    addBodyClasses(config[setting]);
    localStorage.setItem(storageKey, "true");
  } else {
    removeBodyClasses(config[setting]);
    localStorage.removeItem(storageKey);
  }
});

/**
 * Initialise all event listeners for items
 */
document.querySelectorAll("[data-setting]").forEach((element) => {
  const { setting } = element.dataset;

  element.addEventListener("click", onClick(setting));

  window.addEventListener('toggle-setting', (event) => {
    const { newActiveState } = event.detail;

    if (event.detail.setting !== setting) {
      return;
    }

    if (newActiveState) {
      element.classList.add("active");
    } else {
      element.classList.remove("active");
    }

    element.checked = newActiveState;
  });
});

/**
 * Initialise Settings
 */
Object.keys(config).map((setting) => {
  const storageName = `mode-${setting}`;
  if (localStorage.getItem(storageName) != null) {
    addBodyClasses(config[setting]);

    window.dispatchEvent(new CustomEvent("toggle-setting", {
      bubbles: true,
      cancelable: true,
      detail: { setting, newActiveState: true }
    }));
  } else {
    window.dispatchEvent(new CustomEvent("toggle-setting", {
      bubbles: true,
      cancelable: true,
      detail: { setting, newActiveState: false }
    }));
  }
});

/**
 * Initialise Stylesheets
 */
const stylesheetName = "stylesheet";

const stylesheetElements = document.querySelectorAll("[data-stylesheet]");
stylesheetElements.forEach((element) => {
  const { stylesheet } = element.dataset;
  element.addEventListener("click", () => {
    loadCss(stylesheet);
  })
});

const initCss = () => {
  const filename = localStorage.getItem(stylesheetName);
  console.log("INIT CSS", filename);

  if (filename) {
    loadCss(filename);
  }
}

const loadCss = (filename) => {
  const link = document.getElementById("stylesheet");
  let href = link.getAttribute("href").split("/");
  href.pop()
  href.push(filename);

  localStorage.setItem(stylesheetName, filename);

  link.setAttribute("href", href.join("/"));

  stylesheetElements.forEach(element => {
    if (element.dataset.stylesheet === filename) {
      element.classList.add("active");
    } else {
      element.classList.remove("active");
    }
  });
}

initCss();
window.loadCss = loadCss;
